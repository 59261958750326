import React from 'react'
import PropTypes from 'prop-types'
import {Link, graphql, StaticQuery} from 'gatsby'
import { Context } from './Context'


export default class TagsSideWidget extends React.Component {

    render() {
        return (
            <Context.Consumer>
		        {({ lang }) => (
                    <StaticQuery
                        query={graphql`
                        query {
                        allWordpressTag {
                                edges {
                                node {
                                    id
                                    count
                                    name
                                    slug
                                    wordpress_site
                                }
                                }
                            }
                        }
                        `}

                        render={data => (
                            <aside className="widget widget_tags_cloud">
                                <h3 className="widget-title">{lang=='en' ? 'Tags' : 'العلامات'}</h3>
                                <div className="tagcloud">
                                    {data.allWordpressTag.edges.map(({ node: tag }) => (
                                        (tag.wordpress_site === lang && tag.count > 0 &&(
                                            <Link to={"/" + lang + "/tags/"+ decodeURI(tag.slug) } title={tag.name} key={tag.id}>{tag.name}</Link>
                                        ))
                                    ))}
                                </div>
                            </aside>
                        )}
                    />
                )}
            </Context.Consumer>
        )
    }
}
