import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { injectIntl } from 'react-intl'
import { Context } from '../Context'
import favIcon from '../../img/HY_favicon.png'; 

class Head extends Component{

    constructor(props){
        super(props);

        this.pageTitle = '';
    }

    componentDidMount() {
        //function to decode meta title special characters coming from wordpress
        if (typeof window !== 'undefined'){
             const htmlDecode = (input) =>{
                let e =  document.createElement('div');
                e.innerHTML = input;
                return e.childNodes.length === 0 ? "" :e.childNodes[0].nodeValue;
            }
            this.pageTitle = htmlDecode(this.props.seoTitle);
        }
    }

    render(){
        return(
            <Context.Consumer>
                {({ lang }) => (
                    <>
                        <Helmet>
                                <html lang={lang} dir={lang === 'ar' ? 'rtl' : 'ltr'} />
                                <meta name="robots" content="index, follow"/>
                                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                                <link rel="icon" type="image/png" href={favIcon} />
                                <title>{this.props.seoTitle ? 
                                    lang==='en' ? this.pageTitle + ' | ' + 'Hala Yalla' : this.props.seoTitle + ' | ' + 'هلايلا' : ''}</title>
                                <meta name="description" content={this.props.metadesc ? this.props.metadesc : ''}/>
                                <meta name="keywords" content={this.props.metakeywords ? this.props.metakeywords : '' } />
                                <meta content={this.props.title ? this.props.title : ''} name={this.props.title ? this.props.title : ''} />
                                <link rel="canonical" href={this.props.canonical ? this.props.canonical : ''}/>
                                <meta property="og:title" content={this.props.opengraph_title ? this.props.opengraph_title : ''} />
                                <meta property="og:description" content={this.props.opengraph_description ? this.props.opengraph_description : ''} />
                                <meta property="og:image" content={this.props.opengraph_image ? this.props.opengraph_image : ''} />
                                <meta property="og:url" content={this.props.canonical ? this.props.canonical : ''} />
                                <meta property="og:type" content="article"/>
                                <meta name="twitter:card" content="summary_large_image" />
                                <meta name="twitter:title" content={this.props.twitter_title ? this.props.twitter_title : ''} />
                                <meta name="twitter:description" content={this.props.twitter_description ? this.props.twitter_description : ''} />
                                <meta name="twitter:image" content={this.props.twitter_image ? this.props.twitter_image : ''} />


                                {lang=='ar' ?
                                    <link rel="stylesheet" href="https://cdn.rtlcss.com/bootstrap/v4.2.1/css/bootstrap.min.css" integrity="sha384-vus3nQHTD+5mpDiZ4rkEPlnkcyTP+49BhJ4wJeJunw06ZAp+wzzeBPUXr42fi8If" crossorigin="anonymous" />
                                    :
                                    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous" />
                                }
                        </Helmet>
                    </>
                )}
            </Context.Consumer>
        )
    }
}

export default injectIntl(Head)
