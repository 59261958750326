import React from 'react'
import './all.scss'
import axios from 'axios';
import { Context } from './Context';

export default class NewsletterSieWidget extends React.Component {
    state = {
        email: "",
        loading: false,
        response: ''
    }
    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState({
          [name]: value,
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        let errorMsg = document.getElementById('errorMsg');
        let successMsg = document.getElementById('successMsg');

        successMsg.classList.add('d-none');
        errorMsg.classList.add('d-none');

        const formData = new FormData();
        formData.append("email", this.state.email);

        axios.post("https://wp-blog-en.halayalla.com/wp-json/v2/newsletter",formData)
        .then(res => {
            this.setState({
                loading: false,
                email: '',
            });
            if(res.status == 201){
                successMsg.classList.remove('d-none');
                errorMsg.classList.add('d-none');
            }else if(res.status == 200){
                successMsg.classList.add('d-none');
                errorMsg.classList.remove('d-none');
            }
        })
        .catch(err => {
            console.log("error in request", err);
            this.setState({
                loading: false
            });
            serverErrorMsg.classList.add('d-none');
        });
             
    }
    
    render() {
        return (
                <Context.Consumer>
                    {({ lang }) => (
                        <aside className="widget widget_newsletter">
                            <h3 className="widget-title">{ lang==='en' ? 'Newsletter' : 'النشرة الإخبارية'}</h3>
                            <div className="newsletter-box">
                                <i className="ion-ios-email-outline"></i>
                                <h4>{lang==='en' ? 'Sign Up for Newsletter' : 'الاشتراك في النشرة الإخبارية'}</h4>
                                <p>{lang=='en' ? 'Sign up to receive latest posts and news' : 'قم بالتسجيل لتلقي أحدث المشاركات والأخبار' }</p>
                                <form onSubmit={this.handleSubmit}>
                                    <input 
                                        type="email" 
                                        name="email" 
                                        required="required"
                                        className="form-control" 
                                        placeholder={lang=='en' ? 'Your email address' : 'عنوان بريدك الإلكتروني'}
                                        value={this.state.email}
                                        onChange={this.handleInputChange}
                                    />
                                    <input id="submit-btn" type="submit" value={lang === 'en' ? this.state.loading ? 'Sending...' : 'Subscribe Now' : this.state.loading ?'إرسال...' : 'إشترك الآن'}/>
                                </form>
                                <span id="successMsg" className="d-none successMsg">{lang=='en' ? 'You have successfully subscribed.' : 'لقد تم اشتراكك بنجاح'}</span>
                                <span id="errorMsg" className="d-none errorMsg">{lang=='en' ? 'Email you entered is already subscribed.' : 'البريد الإلكتروني الذي أدخلته مسجل بالفعل'}</span>
                            </div>
                        </aside>
                    )}
                </Context.Consumer>
        )
    }
}
