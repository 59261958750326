import React from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import './all.scss';
import { Context } from './Context';
import * as moment from 'moment';
import 'moment/locale/ar';


export default class PopularPosts extends React.Component {

    render() {
        return (
          <Context.Consumer>
            {({ lang }) => (
              <StaticQuery
                query={graphql`
                  query {
                    allWordpressPost(
                      sort: {order: DESC, fields: date}
                      filter: {acf: {post_type:{eq:"Popular"}}}
                    ) {
                        edges {
                          node {
                            id
                            title
                            excerpt
                            acf {
                              post_type
                            }
                            better_featured_image {
                              source_url
                            }
                            author {
                              name
                              slug
                              avatar_urls {
                                wordpress_48
                              }
                            }
                            date(formatString: "MMMM DD, YYYY")
                            slug
                            wordpress_site
                          }
                        }
                      }
                  }
                `}
                render={data => (
                  <aside className="widget widget_latestposts">
                      <h3 className="widget-title">{lang=='en'? 'Popular Posts' : 'منشورات شائعة'}</h3>
                      {data.allWordpressPost.edges.filter(({node: post}) => (post.wordpress_site===lang))
                      .map(({ node: post }, index) => {
                        if(post.acf.post_type){
                          return(
                            (index<10 && (
                              <div className="latest-content" key={post.id}>
                                  <Link to={"/"+lang + "/" + decodeURI(post.slug) + "/"}  title={post.title}><i><img src={ post.better_featured_image ?  post.better_featured_image.source_url : "https://placehold.it/100x80"}
                                                                          className="wp-post-image"
                                                                          alt="blog-1"/></i></Link>
                                  <h5 className="post-title"><Link to={lang + "/" + decodeURI(post.slug) + "/"} title={post.title}><span dangerouslySetInnerHTML={{ __html: post.title }}></span></Link></h5>
                                  <span><Link to={"/"+lang + "/" + decodeURI(post.slug) + "/"} >{lang=='en' ? post.date : moment(post.date).format('MMMM DD, YYYY')}</Link></span>
                              </div>
                            ))
                          )
                        }
                      })}

                  </aside>
                )}
              />
            )}
          </Context.Consumer>
        )
    }
}
