
import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Insta1 from '../assets/images/insta1.jpg';
import Insta2 from '../assets/images/insta2.jpg';
import Insta3 from '../assets/images/insta3.jpg';
import Insta4 from '../assets/images/insta4.jpg';
import Insta5 from '../assets/images/insta5.jpg';
import Insta6 from '../assets/images/insta6.jpg';
import Insta7 from '../assets/images/insta7.jpg';
import Insta8 from '../assets/images/insta8.jpg';
import Insta9 from '../assets/images/insta9.jpg';
import Insta10 from '../assets/images/insta10.jpg';
import Insta11 from '../assets/images/insta11.jpg';
import Insta12 from '../assets/images/insta12.jpg';

const InstagramSideWidget = () => (
    // <StaticQuery
    //   query={graphql`
    //     query {
    //       allInstaNode {
    //           edges {
    //             node {
    //               id
    //               likes
    //               mediaType
    //               preview
    //               original
    //               timestamp
    //               caption
    //               localFile {
    //                 childImageSharp {
    //                   fixed(width: 111, height: 111) {
    //                     ...GatsbyImageSharpFixed
    //                   }
    //                 }
    //               }
    //               thumbnails {
    //                 src
    //                 config_width
    //                 config_height
    //               }
    //               dimensions {
    //                 height
    //                 width
    //               }
    //             }
    //           }
    //         }
    //       }
    //     `}

    //     render={data => (

            <aside className="widget widget_instagram">
                <h3 className="widget-title">Instagram</h3>
                <ul>
                    {/* {data.allInstaNode.edges.map((edge) =>   {
                      return (
                        <>
                          {edge.node.localFile?
                            <li key={edge.node.id}>
                              <a target="_blank" href={'https://www.instagram.com/p/'+edge.node.id}>
                                  <img src={edge.node.localFile.childImageSharp.fixed.src} alt={edge.node.caption}/>
                              </a>
                            </li> : ''
                          }
                          
                        </>
                    )})} */}
                  <li>
                    <a target="_blank" href="https://www.instagram.com/p/CBGW3FsBStJ/">
                        <img src={Insta1} width="111px" height="111px"/>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/p/CBECGyyBRkN/">
                        <img src={Insta2} width="111px" height="111px"/>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/p/CBEMP8HhaQY/">
                        <img src={Insta3} width="111px" height="111px"/>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/p/CBGW6bbhM0p/">
                        <img src={Insta4} width="111px" height="111px"/>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/p/CBELcGoBKln/">
                        <img src={Insta5} width="111px" height="111px"/>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/p/CBEL10BhfTk/">
                        <img src={Insta6} width="111px" height="111px"/>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/p/CBEJdJRhd6C/">
                        <img src={Insta7} width="111px" height="111px"/>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/p/CBECL11ho1P/">
                        <img src={Insta8} width="111px" height="111px"/>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/p/CBECJLLBlAP/">
                        <img src={Insta9} width="111px" height="111px"/>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/p/CBGW4rsBEkp/">
                        <img src={Insta10} width="111px" height="111px"/>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/p/CBEJkq1hLsv/">
                        <img src={Insta11} width="111px" height="111px"/>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://www.instagram.com/p/CBEJV9ahTtl/">
                        <img src={Insta12} width="111px" height="111px"/>
                    </a>
                  </li>
                </ul>
            </aside>
    //     )}
    // />
)

export default InstagramSideWidget