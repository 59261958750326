import React from 'react';
import { Context } from './Context';

const FollowUsSocialSideWidget =() => (
			<Context.Consumer>
				{({ lang }) => (
                    <aside className="widget widget_social">
                        <h3 className="widget-title">{lang=='en' ? 'FOLLOW US' : 'تابعنا'}</h3>
                        <ul>
                            <li><a href="https://www.facebook.com/halayalla/" target="_blank" title=""><i className="ion-social-facebook-outline"></i></a></li>
                            <li><a href="https://twitter.com/halayalla" target="_blank" title=""><i className="ion-social-twitter-outline"></i></a></li>
                            <li><a href="https://www.instagram.com/halayalla" target="_blank" title=""><i className="ion-social-instagram-outline"></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UCTKL7NdBf3HYPv66C3Y0-PA" target="_blank" title=""><i className="ion-social-youtube-outline"></i></a></li>
                        </ul>
                    </aside>
                )}
            </Context.Consumer>
);

export default FollowUsSocialSideWidget;
