import React from 'react';
import PropTypes from 'prop-types';
import {Link, graphql, StaticQuery} from 'gatsby';
import { Context } from './Context';

var decode = require('unescape');


export default class CategoriesPosts extends React.Component {
    render() {
        return (
            <Context.Consumer>
		        {({ lang }) => (
                    <StaticQuery
                        query={graphql`
                        query {
                        allWordpressCategory {
                                edges {
                                node {
                                    id
                                    name
                                    slug
                                    count
                                    wordpress_site
                                }
                                }
                            }
                        }
                        `}

                        render={data => (
                            <aside className="widget widget_categories text-center">
                                <h3 className="widget-title">{lang=='en' ? 'Categories' : 'التصنيفات'}</h3>
                                <ul>
                                    {data.allWordpressCategory.edges.map(({ node: category }) => (
                                        (category.wordpress_site === lang && category.count > 0 &&(
                                            <li key={category.slug}>
                                                <Link to={"/" + category.wordpress_site + "/categories/"+ decodeURI(category.slug) +"/"} title={decode(category.name)}>{decode(category.name)}</Link>
                                            </li>
                                        ))
                                    ))}
                                </ul>
                            </aside>
                        )}
                    />
                )}
            </Context.Consumer>
        )
    }
}
